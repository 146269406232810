import React, { FC, useCallback } from "react";
import { HashLink } from "react-router-hash-link";
import { useMediaQuery } from "@material-ui/core";

interface IProps {
  to: string;
}

const Link: FC<IProps> = ({ to, children }) => {
  const maxH850 = useMediaQuery("(max-height: 850px)");

  const scrollWithOffset = useCallback(
    (el: HTMLElement) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -(maxH850 ? 90 : 110);
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    },
    [maxH850]
  );

  return (
    <HashLink to={to} scroll={(el) => scrollWithOffset(el)}>
      {children}
    </HashLink>
  );
};

export default Link;
