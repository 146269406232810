import { useState } from "react";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";

const useScrollPos = (): number => {
  const [scroll, setScroll] = useState(0);

  useScrollPosition(
    ({ currPos }) => setScroll(Math.abs(currPos.y)),
    [],
    undefined,
    false,
    100
  );

  return scroll;
};

export default useScrollPos;
