import React from "react";
import { Helmet } from "react-helmet";

const RentSenter = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Informasjon om våre tjenester utover trening slik som butikk/shop, solarium, og gratis Wi-Fi."
        />
      </Helmet>

      <div>
        <h1>RENT SENTER</h1>
        <h3>Sertifisering</h3>
        Top Notch Fitness ble sertifisert som{" "}
        <a
          href="https://www.antidoping.no/forebygging/rent-senter"
          target="_blank"
          rel="noreferrer"
        >
          Rent Senter
        </a>{" "}
        20. september 2021.
        <br />
        <br />
        Vi i Top Notch Fitness er opptatt av å ha et dopingfritt miljø. Derfor
        er vi medlem av Rent Senter.
        <br />
        Å være medlem av Rent Senter styrker vårt antidopingarbeid og er et
        viktig signal vi ønsker å sende ut.
        <br />
        <h3>Antidopingansvarlig</h3>
        Marte Kristoffersen er antidopingansvarlig på Top Notch Fitness.
        <br />
        Hvis du har spørsmål eller tips du vil dele, ta kontakt med henne på{" "}
        <a href="tel:004740473413">404 73 413</a> eller{" "}
        <a href="mailto:marte.kristoffersen@topnotchfitness.no" target="_top">
          marte.kristoffersen@topnotchfitness.no
        </a>
        .
        <br />
        <h3>Mer informasjon</h3>
        Se{" "}
        <a
          href={`${process.env.PUBLIC_URL}/documents/adno-rent-senter-tren-rent.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          brosjyre om Rent Senter-programmet
        </a>
        .
        <br />
        <br />
        Se{" "}
        <a
          href="https://www.antidoping.no/forebygging/rent-senter/rent-senter-oversikt"
          target="_blank"
          rel="noreferrer"
        >
          oversikt over hvilke treningssentre som er medlem av Rent Senter
        </a>
        .
      </div>
    </>
  );
};

export default RentSenter;
