import React from "react";
import { Route, Switch } from "react-router-dom";
import { Container, createStyles, makeStyles } from "@material-ui/core";

import GeneralInfo from "pages/GeneralInfo";
import Venue from "pages/Venue";
import OtherServices from "pages/OtherServices";
import RentSenter from "pages/RentSenter";
import Faq from "pages/Faq";
import Start from "pages/Start";
import PersonalTrainer from "pages/PersonalTrainer";
import OrderTryout from "pages/OrderTryout";

const Routes = () => (
  <Switch>
    <Route exact path="/" component={Start} />
    <Route exact path="/*" component={PageRoutes} />
    <Route exact path="*" component={Start} />
  </Switch>
);

const useStyles = makeStyles(({ spacing, breakpoints }) =>
  createStyles({
    root: {
      [breakpoints.only("xs")]: {
        padding: `0 ${spacing(2)}px`,
      },
      padding: `0 ${spacing(8)}px`,
    },
    container: {
      marginTop: 32,
      marginBottom: 32,
    },
  })
);

const PageRoutes = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container className={classes.container} disableGutters>
        <Switch>
          <Route path="/generell-info" component={GeneralInfo} />
          <Route path="/lokalet" component={Venue} />
          <Route path="/andre-tjenester" component={OtherServices} />
          <Route path="/rent-senter" component={RentSenter} />
          <Route exact path="/ofte-stilte-sporsmal" component={Faq} />
          <Route exact path="/personlig-trener" component={PersonalTrainer} />
          <Route exact path="/bestill-provetime" component={OrderTryout} />
        </Switch>
      </Container>
    </div>
  );
};

export default Routes;
