import React, { MutableRefObject, useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { createStyles, lighten, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      height: "calc(100vw - 64px)",
      maxHeight: 400,
      border: "1px solid " + lighten(palette.primary.main, 0.7),
      borderRadius: 5,
    },
  })
);

const loader = new Loader({
  apiKey: "AIzaSyClkKzex4sECe4QbdAL5oHDd4oV9Z8BUJY",
});

const mapOptions = {
  center: { lat: 59.66323, lng: 9.6441 },
  zoom: 19,
  mapTypeControl: false,
};
const roofParking = { lat: 59.66309, lng: 9.64405 };

const markers = [
  {
    name: "Inngang",
    position: { lat: 59.66328, lng: 9.64426 },
    icon: `${process.env.PUBLIC_URL}/map/maps_locator.png`,
    className: "entrance-map-marker",
  },
  {
    name: "Takparkering",
    position: { lat: 59.66309, lng: 9.64418 },
    icon: `${process.env.PUBLIC_URL}/map/parking_symbol.png`,
  },
  {
    name: "Parkering",
    position: { lat: 59.66319, lng: 9.64436 },
    icon: `${process.env.PUBLIC_URL}/map/parking_symbol.png`,
  },
  {
    name: "Sykkelparkering",
    position: { lat: 59.663285, lng: 9.6441 },
    icon: `${process.env.PUBLIC_URL}/map/bike_symbol.png`,
    className: "bike-map-marker",
  },
];

const ParkingMap = () => {
  const classes = useStyles();

  const mapRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    loader
      .load()
      .then(() => {
        // @ts-ignore
        const map = new google.maps.Map(mapRef.current, mapOptions);

        // Add markers to the map
        markers.forEach((m) => {
          // @ts-ignore
          new google.maps.Marker({
            label: {
              text: m.name,
              className: m.className ?? "map-marker",
            },
            position: m.position,
            icon: m.icon,
            map: map,
          });
        });

        // Draw directions from front door to the roof parking on the map
        // @ts-ignore
        const directionsService = new google.maps.DirectionsService();
        // @ts-ignore
        const directionsRenderer = new google.maps.DirectionsRenderer({
          preserveViewport: true,
          markerOptions: { visible: false },
        });
        directionsRenderer.setMap(map);

        // @ts-ignore
        const start = new google.maps.LatLng(59.6633, 9.64425);
        // @ts-ignore
        const end = new google.maps.LatLng(roofParking.lat, roofParking.lng);
        // @ts-ignore
        const bounds = new google.maps.LatLngBounds();
        bounds.extend(start);
        bounds.extend(end);

        const request = {
          origin: start,
          destination: end,
          // @ts-ignore
          waypoints: [{ location: new google.maps.LatLng(59.66316, 9.6433) }],
          // @ts-ignore
          travelMode: google.maps.TravelMode.DRIVING,
        };
        directionsService.route(request, (response: any, status: any) => {
          // @ts-ignore
          if (status === google.maps.DirectionsStatus.OK) {
            directionsRenderer.setDirections(response);
          } else {
            alert(
              "Directions Request from " +
                start.toUrlValue(6) +
                " to " +
                end.toUrlValue(6) +
                " failed: " +
                status
            );
          }
        });
      })
      .catch(() => {});
  }, []);

  return <div className={classes.root} ref={mapRef} />;
};

export default ParkingMap;
