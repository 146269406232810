import React, { useState } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Moment } from "moment";
import { Typography, createStyles, makeStyles } from "@material-ui/core";

import SuccessMessage from "./SuccessMessage";
import OrderForm from "./OrderForm";

export type TryoutType = "tryout" | "dropin";
export type Arriving = "withMember" | "alone";

export interface ITryout {
  type: TryoutType;
  firstName: String;
  lastName: String;
  birthdate: Moment;
  email: String;
  date: Moment;
  arriving: Arriving;
  memberFullName: String;
}

const formatEmail = ({
  type,
  firstName,
  lastName,
  birthdate,
  email,
  date,
  arriving,
  memberFullName,
}: ITryout): string => {
  const typeString = `<b>${type === "tryout" ? "Prøvetime" : "Drop-in"}</b>`;
  const nameString = `Navn: ${firstName} ${lastName}`;
  const birthdateString = `Bursdag: ${birthdate.format("DD.MM.YYYY")}`;
  const emailString = `E-post: ${email}`;
  const dateString = `Dato og tid: ${date.format("dddd DD.MM.YYYY kk:mm")}`;
  const arrivingString = `Ankommer: ${
    arriving === "withMember" ? "Med medlem" : "Alene"
  }`;
  const memberFullNameString =
    arriving === "withMember" ? `Medlem: ${memberFullName}` : null;

  return `
    ${typeString}<br>
    ${nameString}<br>
    ${birthdateString}<br>
    ${emailString}<br>
    ${dateString}<br>
    ${arrivingString}<br>
    ${memberFullNameString !== null ? memberFullNameString : ""}
  `;
};

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    formSection: {
      position: "relative",
      margin: "auto",
    },

    formOverlay: {
      opacity: 0.15,
    },

    successPage: {
      display: "block",
      position: "absolute",
      bottom: "200px",
      width: "100%",

      [breakpoints.only("xs")]: {
        bottom: "80px",
      },
    },
  })
);

const OrderTryout = () => {
  const classes = useStyles();

  const [isSuccessMessageShown, setSuccessMessageShown] = useState(false);

  const sendOrderTryoutForm = (tryout: ITryout) => {
    const data = formatEmail(tryout);

    axios
      .post(
        `${process.env.REACT_APP_SOCIUS_BACKEND}/users/send-tryout-email`,
        data,
        { headers: { "Content-Type": "text/plain" } }
      )
      .then(() => setSuccessMessageShown(true))
      .catch(() => alert("Det skjedde en feil. Ta kontakt."));
  };

  return (
    <>
      <Helmet>
        <title>Bestill prøvetime</title>
        <meta name="description" content="Bestill prøvetime online" />
      </Helmet>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h1">BESTILL PRØVETIME/DROP-IN</Typography>

        <Typography variant="body1" paragraph>
          Bestill en prøvetime eller drop-in i skjemaet nedenfor.
        </Typography>

        <Typography variant="body1" paragraph>
          Det er ikke nødvendig å bestille prøvetime på forhånd hvis du skal
          prøve i resepjonstiden; mandag - fredag, kl 16:00 - 18:00. Da kan du
          bare møte opp i resepsjonen.
        </Typography>

        <Typography variant="body1" paragraph>
          <b>Obs!</b> Prøvetimen er ikke godkjent før du har mottatt svar på
          e-posten oppgitt i skjemaet. Husk å sjekke søppelpost hvis du ikke
          mottar svar.
        </Typography>

        <Typography variant="body1" paragraph>
          <b>Obs!</b> Du må være fylt 16 år.
        </Typography>
        <div className={classes.formSection}>
          <div
            className={isSuccessMessageShown ? classes.formOverlay : undefined}
          >
            <OrderForm onSuccess={sendOrderTryoutForm} />
          </div>

          {isSuccessMessageShown && (
            <div className={classes.successPage}>
              <SuccessMessage />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default OrderTryout;
