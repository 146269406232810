import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import moment from "moment";

import useQuery from "hooks/useQuery";
import Layout from "Layout";
import Routes from "Routes";

moment.locale("nb");

const organizationLdJson = {
  "@context": "https://schema.org",
  "@type": ["Organization", "ExerciseGym"],
  "@id": "https://topnotchfitness.no",
  name: "Top Notch Fitness",
  alternateName: "TNF",
  legalName: "Top Notch Fitness AS",
  description:
    "Self-training center on the west side of Kongsberg with quality equipment and excellent facilities for cardiovascular training, strength training and functional training.",
  image: ["https://topnotchfitness.no/logos/logo.svg"],
  logo: "https://topnotchfitness.no/logos/logo.svg",
  address: {
    "@type": "PostalAddress",
    addressCountry: "Norway",
    addressLocality: "Kongsberg",
    addressRegion: "Buskerud",
    postalCode: "3616",
    streetAddress: "Bussedalen 7",
  },
  openingHours: "Mo-Su 05:00-23:00",
  specialOpeningHoursSpecification: "Mo-Fr 16:00-18:00",
  telephone: "+47 404 73 413",
  email: "kontakt@topnotchfitness.no",
  url: "https://topnotchfitness.no",
  priceRange: "NOK 249-449",
  paymentAccepted: "Card, Invoice, AvtaleGiro, Vipps (store only)",
  geo: {
    "@type": "GeoCoordinates",
    latitude: 59.66342223457659,
    longitude: 9.644214913804966,
  },
  foundingDate: "2017-08-21",
  vatID: "918601414MVA",
};

const webSiteLdJson = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Top Notch Fitness",
  alternateName: "TNF",
  url: "https://topnotchfitness.no",
};

const App = () => {
  const pathname = useLocation().pathname;
  const history = useHistory();

  const langParam = useQuery().get("lang");

  const { i18n } = useTranslation();

  // Append the lang query param after a page change
  useEffect(() => {
    const params = new URLSearchParams();
    if (i18n.language !== "no") params.append("lang", i18n.language);
    history.replace({ search: params.toString() });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, history]);

  // Update the locale when the lang query param is changed
  useEffect(() => {
    const value = !langParam ? "no" : langParam;
    if (value !== i18n.language) i18n.changeLanguage(value);
  }, [langParam, i18n]);

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    window.scrollTo({ top: 0 });
    document.documentElement.style.scrollBehavior = "smooth";
  }, [pathname]);

  return (
    <>
      <Helmet titleTemplate="Top Notch Fitness | %s">
        <script type="application/ld+json">
          {JSON.stringify(organizationLdJson)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(webSiteLdJson)}
        </script>
      </Helmet>

      <Layout>
        <Routes />
      </Layout>
    </>
  );
};

export default App;
