import React from "react";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles, Typography, Grid } from "@material-ui/core";

const useStyles = makeStyles(() =>
  createStyles({
    topSection: {
      alignItems: "center",
      "& > div > img": {
        width: "100%",
        height: "75%",
        border: "1px solid #aaaaaa",
      },
    },
  })
);

const PersonalTrainer = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <meta name="description" content="Informasjon om personlig trener" />
      </Helmet>

      <div>
        <Typography variant="h1">PERSONLIG TRENER</Typography>
        <Grid container className={classes.topSection}>
          <Grid item container xs={12} sm={12} md={6}>
            <Typography id="personligtrener" variant="h3" component="h2">
              Ønsker du å jobbe som personlig trener hos Top Notch Fitness?
            </Typography>

            <Typography variant="body1" paragraph>
              Jobb som PT med svært gode betingelser! <br />
              <br />
              <b>Stillingstittel</b>
              <br />
              Personlig Trener <br />
              <br />
              <b>Stillingsprosent</b>
              <br />
              Fulltid/Deltid <br />
              <br />
              <b>Ansettelsesform</b>
              <br />
              Selvstendig næringsdrivende <br />
              <br />
              <b>Frist</b>
              <br />
              Så raskt som mulig.
            </Typography>
          </Grid>

          <Grid item container xs={12} sm={12} md={6}>
            <img
              src={`${process.env.PUBLIC_URL}/images/personal-trainer.jpg`}
              alt="Personlig trener hjelper klient ved boksesekken"
            />
          </Grid>
        </Grid>

        <br />

        <Typography variant="body1" paragraph>
          Vi utvider konseptet vårt og trenger DEG som personlig trener!
        </Typography>

        <Typography variant="body1" paragraph>
          Våre betingelser er blant de beste på markedet for personlige trenere.{" "}
          <br />
          Vi ønsker å legge til rette for at DU skal lykkes og at du får igjen
          for innsatsen som DU legger ned! <br />
          Hos oss er det ingen personlige trenere fra før. Det er derfor et
          stort potensial for å opparbeide seg en god kundegruppe.
        </Typography>

        <Typography variant="body1" paragraph>
          <b>Vi ser etter personlige trenere som:</b>
        </Typography>

        <Typography variant="body1" paragraph>
          <ul>
            <li>
              Er sertifisert PT fra Norges Idrettshøgskole, AFPT, Active
              Education eller lignende. Minimum årsstudium.
            </li>
            <li>Kan drive sitt eget foretak.</li>
            <li>Ønsker å jobbe fulltid eller mer enn 50% som PT.</li>
            <li>
              Har god kunnskap innen styrke- og utholdenhetstrening. Gjerne også
              innen funksjonell trening.
            </li>
            <li>
              Jobber godt selvstendig, har bein i nesa, setter mål og tør å
              satse på seg selv.
            </li>
            <li>
              Tar initiativ, har god arbeidskapasitet, og tar arbeidet seriøst.
            </li>
            <li>
              Tar ansvar for eget salg, tør å være frempå og står på selv når
              ting er tøft.
            </li>
            <li>
              Har utstråling, er motiverende, hjelpende, tålmodig, og opptatt av
              treningsglede.
            </li>
            <li>
              Ser mennesker, skaper trygge relasjoner og evner å gi kundene
              individuelle mål og planer.
            </li>
          </ul>
        </Typography>

        <Typography variant="body1" paragraph>
          <b>Passer denne beskrivelsen deg?</b>
        </Typography>

        <Typography variant="body1" paragraph>
          Send en søknad til daglig leder Marte Kristoffersen på e-post{" "}
          <a href="mailto:marte.kristoffersen@topnotchfitness.no" target="_top">
            marte.kristoffersen@topnotchfitness.no
          </a>
          , eller ta kontakt på telefonnummer{" "}
          <a href="tel:004740473413">404 73 413</a>.<br />
          Vi ser frem til å høre fra DEG!
        </Typography>
      </div>
    </>
  );
};

export default PersonalTrainer;
