import React, { useState, FC } from "react";
import moment, { Moment } from "moment";
import {
  Button,
  createStyles,
  lighten,
  makeStyles,
  Typography,
  TextField,
  ListItemIcon,
  Grid,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  FormHelperText,
  Tooltip,
} from "@material-ui/core";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import { Help } from "@material-ui/icons";

import { TryoutType, Arriving, ITryout } from "./index";

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    form: {
      maxWidth: 500,
      flex: 1,
      marginTop: spacing(6),
      marginBottom: spacing(4),

      "& .MuiTextField-root": {
        width: "100%",
      },

      "& .MuiListItemIcon-root": {
        minWidth: "0",
      },

      "&.hide": {
        display: "none",
      },
    },

    successPage: {
      maxWidth: 500,
      flex: 1,
      alignSelf: "center",
      marginTop: spacing(6),
      marginBottom: spacing(4),

      "&.show": {
        display: "block",
      },
    },

    checkIcon: {
      color: "#4caf50",
    },

    header: {
      textAlign: "center",
    },

    radioInput: {
      marginTop: "20px",

      "& .MuiFormLabel-root": {
        color: "rgba(0, 0, 0, 0.87)",
      },
    },

    label: {
      marginBottom: "5px",
      display: "flex",
      alignItems: "center",

      "& svg": {
        fontSize: "1.1rem",
      },
    },

    buttonContainer: {
      width: "100%",
      marginTop: spacing(4),
      textAlign: "center",
    },

    gotoButton: {
      color: "white !important",
      backgroundColor: palette.primary.main,

      "&:hover": {
        backgroundColor: lighten(palette.primary.main, 0.2),
      },
    },

    "#member-input": {
      display: "none",
    },
  })
);

const emailRegexPatternName = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
const emailRegexPatternEmail = /^[\w-.]+@([\w-]+\.)+[a-zA-Z]{2,4}$/;
const emailRegexPatternFullName = /^([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]{2,}\s[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]{1,}'?-?[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]{1,}\s?([a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]{2,})?)/;

const tooltipText =
  "Prøvetime er for dem som vurderer å begynne og trene hos oss, men som ønsker å prøve først. Drop-in er enten for dem som kun er innom for å ta en økt uten planer om innmelding eller som allerede har benyttet seg av gratis prøvetime, men vil prøve igjen.";

interface IProps {
  onSuccess: (tryout: ITryout) => void;
}

const OrderForm: FC<IProps> = ({ onSuccess }) => {
  const classes = useStyles();

  // Form values
  const [tryoutType, setTryoutType] = useState<TryoutType | null>(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [birthdate, handleBirthdate] = useState(moment());
  const [email, setEmail] = useState("");
  const [date, handleDateChange] = useState(moment());
  const [arriving, setArriving] = useState<Arriving | null>(null);
  const [memberFullName, setMemberFullName] = useState("");

  // Validation
  const [isTryoutTypeValid, setTryoutTypeValid] = useState(true);
  const [isFirstNameValid, setFirstNameValid] = useState(true);
  const [isLastNameValid, setLastNameValid] = useState(true);
  const [isAgeValid, setAgeValid] = useState(true);
  const [isEmailValid, setEmailValid] = useState(true);
  const [isWithMemberValid, setWithMemberValid] = useState(true);
  const [isMemberFullNameValid, setMemberFullNameValid] = useState(true);

  const [disableButton, setDisableButton] = useState(false);

  const validateTryout = (tryout: TryoutType | null) => {
    const valid = !!tryout;
    setTryoutTypeValid(valid);
    return valid;
  };

  const validateWithMember = (arriving: Arriving | null) => {
    const valid = !!arriving;
    setWithMemberValid(valid);
    return valid;
  };

  const validateFirstName = (firstName: string) => {
    const valid = emailRegexPatternName.test(firstName);
    setFirstNameValid(valid);
    return valid;
  };

  const validateLastName = (lastName: string) => {
    const valid = emailRegexPatternName.test(lastName);
    setLastNameValid(valid);
    return valid;
  };

  function validateAge(value: Moment) {
    let g = moment().diff(moment(value, "DD.MM.YYYY"), "years");
    const valid = g >= 15;
    setAgeValid(valid);
    handleBirthdate(value);
    return valid;
  }

  const validateEmail = (email: string) => {
    const valid = emailRegexPatternEmail.test(email);
    setEmailValid(valid);
    return valid;
  };

  const validateMemberFullName = (memberFullName: string) => {
    const valid = emailRegexPatternFullName.test(memberFullName);
    setMemberFullNameValid(valid);
    return valid;
  };

  function orderTryout() {
    const typeIsValid = validateTryout(tryoutType);
    const firstNameIsValid = validateFirstName(firstName);
    const lastNameIsValid = validateLastName(lastName);
    const birthdateIsValid = validateAge(birthdate);
    const emailIsValid = validateEmail(email);
    const withMemberIsValid = validateWithMember(arriving);
    const memberFullNameIsValid =
      arriving !== "withMember" || validateMemberFullName(memberFullName);

    const allValid =
      typeIsValid &&
      firstNameIsValid &&
      lastNameIsValid &&
      birthdateIsValid &&
      emailIsValid &&
      withMemberIsValid &&
      memberFullNameIsValid;

    if (allValid) {
      setDisableButton(true);

      const tryout: ITryout = {
        type: tryoutType!,
        firstName,
        lastName,
        birthdate,
        email,
        date,
        arriving: arriving!,
        memberFullName,
      };
      onSuccess(tryout);
    }
  }

  return (
    <Grid container className={classes.form} spacing={2}>
      <Grid item xs={12} className={classes.header}>
        <Typography variant="h5">Bestill prøvetime/drop-in</Typography>
      </Grid>

      <Grid item xs={12}>
        <FormControl className={classes.radioInput} error={!isTryoutTypeValid}>
          <FormLabel
            required
            className={classes.label}
            id="radio-buttons-group-label-tryout"
          >
            <span>Hva ønsker du å bestille?</span>
            <Tooltip title={tooltipText} arrow>
              <ListItemIcon>
                <Help />
              </ListItemIcon>
            </Tooltip>
          </FormLabel>

          <RadioGroup
            row
            aria-labelledby="radio-buttons-group-label-tryout"
            name="row-radio-buttons-group-tryout"
            value={tryoutType}
            onChange={(e) => {
              setTryoutType(e.target.value as TryoutType);
              setTryoutTypeValid(true);
            }}
          >
            <FormControlLabel
              value="tryout"
              control={<Radio color="primary" />}
              label="Prøvetime"
            />
            <FormControlLabel
              value="dropin"
              control={<Radio color="primary" />}
              label="Drop-in"
            />
          </RadioGroup>
          <FormHelperText>
            {!isTryoutTypeValid ? "Du må velge én." : null}
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="body1">Fornavn</Typography>
        <TextField
          required
          id="outlined-required"
          variant="outlined"
          placeholder="Ola"
          value={firstName}
          error={!isFirstNameValid}
          onChange={(e) => setFirstName(e.target.value)}
          onBlur={(e) => validateFirstName(e.target.value)}
          helperText={!isFirstNameValid ? "Ugyldig navn" : null}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="body1">Etternavn</Typography>
        <TextField
          required
          id="outlined-required"
          variant="outlined"
          placeholder="Nordmann"
          value={lastName}
          error={!isLastNameValid}
          onChange={(e) => setLastName(e.target.value)}
          onBlur={(e) => validateLastName(e.target.value)}
          helperText={!isLastNameValid ? "Ugyldig navn" : null}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="body1">Fødselsdato</Typography>

        <DatePicker
          value={birthdate}
          views={["year", "month", "date"]}
          openTo="year"
          format="DD.MM.YYYY"
          okLabel="OK"
          cancelLabel="AVBRYT"
          inputVariant="outlined"
          error={!isAgeValid}
          onChange={(date: any) => validateAge(date ?? moment())}
          helperText={!isAgeValid ? "Du må være fylt 15 år." : null}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="body1">E-post</Typography>
        <TextField
          required
          value={email}
          name="email"
          id="outlined-required-email"
          variant="outlined"
          placeholder="ola@nordmann.no"
          type="email"
          error={!isEmailValid}
          onChange={(e) => setEmail(e.target.value)}
          onBlur={(e) => validateEmail(e.target.value)}
          helperText={!isEmailValid ? "Ugyldig e-post" : null}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="body1">Når ønsker du prøvetime?</Typography>

        <DatePicker
          value={date}
          minDate={moment()}
          format="DD.MM.YYYY"
          okLabel="OK"
          cancelLabel="AVBRYT"
          inputVariant="outlined"
          onChange={(date: any) => handleDateChange(date ?? moment())}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <Typography variant="body1">Ca tidspunkt?</Typography>

        <TimePicker
          ampm={false}
          value={date}
          okLabel="OK"
          cancelLabel="AVBRYT"
          inputVariant="outlined"
          onChange={(time: any) => handleDateChange(time ?? moment())}
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl className={classes.radioInput} error={!isWithMemberValid}>
          <FormLabel
            required
            className={classes.label}
            id="radio-buttons-group-label-arriving"
          >
            Kommer du...
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="radio-buttons-group-label-arriving"
            name="row-radio-buttons-group-arriving"
            value={arriving}
            onChange={(e) => {
              setArriving(e.target.value as Arriving);
              setWithMemberValid(true);
            }}
          >
            <FormControlLabel
              value="withMember"
              control={<Radio color="primary" />}
              label="Med et medlem"
            />
            <FormControlLabel
              value="alone"
              control={<Radio color="primary" />}
              label="Alene"
            />
          </RadioGroup>
          <FormHelperText>
            {!isWithMemberValid ? "Du må velge én." : null}
          </FormHelperText>
        </FormControl>
      </Grid>

      {arriving === "withMember" && (
        <Grid item xs={12} id="member-input">
          <Typography variant="body1">Medlems fulle navn</Typography>

          <TextField
            required
            error={!isMemberFullNameValid}
            id="outlined-required"
            variant="outlined"
            placeholder="Kari Nordmann"
            value={memberFullName}
            onChange={(e) => setMemberFullName(e.target.value)}
            onBlur={(e) => validateMemberFullName(e.target.value)}
            helperText={!isMemberFullNameValid ? "Ugyldig navn." : null}
          />
        </Grid>
      )}

      <Grid item className={classes.buttonContainer}>
        <Button
          className={classes.gotoButton}
          variant="contained"
          size="large"
          disabled={disableButton}
          onClick={orderTryout}
        >
          Bestill
        </Button>
      </Grid>
    </Grid>
  );
};

export default OrderForm;
