import React from "react";
import { Container, createStyles, Grid, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import Link from "components/Link";

const useStyles = makeStyles(({ palette, spacing, breakpoints, typography }) =>
  createStyles({
    root: {
      backgroundColor: palette.primary.main,
      padding: `${spacing(4)}px ${spacing(8)}px`,
    },
    container: {
      color: "white",
      fontSize: typography.pxToRem(16),

      "& a": {
        color: "white",
        textDecoration: "none",

        "&:hover": {
          textDecoration: "underline",
        },
      },

      "& > div > .MuiGrid-item": {
        "& > span:first-of-type": {
          fontSize: typography.pxToRem(11),
          textTransform: "capitalize",
          textDecoration: "underline",
          marginBottom: 3,
        },

        "&:last-child": {
          textAlign: "right",
          justifyContent: "flex-end",
        },

        [breakpoints.only("xs")]: {
          textAlign: "center",
          justifyContent: "center",

          "&:nth-child(2n)": {
            textAlign: "center",
            justifyContent: "center",
          },
        },

        "&:nth-child(2)": {
          "& > .MuiGrid-item": {
            "&:first-child": {
              "&:first-child": {
                [breakpoints.only("sm")]: {
                  paddingRight: "5",
                },
              },
            },
          },
        },
      },

      "& > div > .MuiGrid-item > .MuiGrid-container > .MuiGrid-item": {
        "&:last-child": {
          marginLeft: "10px",
        },
        [breakpoints.only("xs")]: {
          "&:last-child": {
            margin: "0 10px",
          },
        },
      },
      "& > div > .MuiGrid-item > .MuiGrid-container": {
        justifyContent: "end",

        "&:last-child": {
          alignItems: "end",

          "&:last-child": {
            alignItems: "center",
          },
        },

        [breakpoints.only("xs")]: {
          justifyContent: "center",
        },
      },
    },
    logoSection: {
      display: "inline-block",

      [breakpoints.only("xs")]: {
        display: "block",
      },
    },
    footerHeader: {
      fontSize: typography.pxToRem(11),
      textTransform: "capitalize",
      textDecoration: "underline",
      marginBottom: 3,
    },
    soMeLogo: {
      height: 50,
    },
    tnLogo: {
      height: 50,
    },
    link: {
      alignSelf: "end",
      textAlign: "center",
    },
  })
);

const Footer = () => {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Container className={classes.container} disableGutters>
        <Grid container spacing={4} justify="center">
          <Grid item xs={12} sm={6}>
            <span id="kontakt">{t("app.contact")}</span>

            <div>
              <a
                href="http://m.me/tnfkongsberg"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  style={{ height: 12, marginRight: 2 }}
                  src={`${process.env.PUBLIC_URL}/facebook.svg`}
                  alt="Facebook Logo"
                />
                -<span style={{ marginLeft: 2 }}>chat</span>
              </a>
            </div>

            <div>
              <a href="tel:004740473413">404 73 413</a>
            </div>

            <div>
              <a href="mailto:kontakt@topnotchfitness.no" target="_top">
                kontakt@topnotchfitness.no
              </a>
            </div>
          </Grid>

          <Grid item container xs={12} sm={6} id="logos">
            <Grid
              item
              className={classes.logoSection}
              xs={12}
              sm={7}
              md={8}
              lg={9}
              id="SoMe"
            >
              <div className={classes.footerHeader}>
                {t("app.social_media")}
              </div>

              <div style={{ marginTop: 6, marginBottom: 10 }}>
                <span style={{ marginRight: 8 }}>
                  <a
                    href="https://www.facebook.com/tnfkongsberg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={classes.soMeLogo}
                      src={`${process.env.PUBLIC_URL}/facebook.svg`}
                      alt="Facebook Logo"
                    />
                  </a>
                </span>

                <span style={{ marginLeft: 8 }}>
                  <a
                    href="https://www.instagram.com/tnfkongsberg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={classes.soMeLogo}
                      src={`${process.env.PUBLIC_URL}/instagram.png`}
                      alt="Instagram Logo"
                    />
                  </a>
                </span>
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              lg={3}
              className={classes.logoSection}
            >
              <div className={classes.footerHeader}>
                {t("app.certifications")}
              </div>

              <div style={{ marginTop: 6 }}>
                <span>
                  <a
                    href="https://www.antidoping.no/forebygging/rent-senter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className={classes.soMeLogo}
                      src={`${process.env.PUBLIC_URL}/logos/rent-senter.png`}
                      alt="Rent Senter Logo"
                    />
                  </a>
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={4} justify="center">
          <Grid item xs={12} sm={4}>
            <div className={classes.footerHeader}>{t("app.company_info")}</div>
            <div>Top Notch Fitness AS</div>
            <div>918 601 414</div>
            <div>Bussedalen 7, 3616 Kongsberg</div>
          </Grid>

          <Grid className={classes.link} item xs={12} sm={4}>
            <Link to="/personlig-trener">{t("app.work_as_pt")}</Link>
          </Grid>

          <Grid item xs={12} sm={4} container style={{ alignItems: "end" }}>
            <Grid container alignItems="center">
              <Grid item style={{ display: "flex" }}>
                <img
                  className={classes.tnLogo}
                  src={`${process.env.PUBLIC_URL}/logos/logo-small.svg`}
                  alt="Top Notch Fitness Logo"
                />
              </Grid>

              <Grid item>
                &copy; {new Date().getFullYear()} av Top Notch Fitness AS
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Footer;
