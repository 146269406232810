import React from "react";
import { Helmet } from "react-helmet";
import { Typography } from "@material-ui/core";
import ParkingMap from "../components/ParkingMap";

const Venue = () => {
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Informasjon om våre garderober, treningsutstyr, og parkering."
        />
      </Helmet>

      <div>
        <Typography id="lokalet" variant="h1" component="h1">
          LOKALET
        </Typography>

        <Typography id="garderober" variant="h3" component="h2">
          Garderober
        </Typography>

        <Typography variant="body1" paragraph>
          Våre garderober består av single dusjer med avkledningsrom, toaletter,
          speil, garderobeskap og krakker. Lås til garderobeskap må medbringes
          eller kjøpes i shopen.
        </Typography>

        <Typography id="utstyr" variant="h3" component="h2">
          Treningsutstyr
        </Typography>

        <Typography variant="body1" paragraph>
          Vi har treningsutstyr for funksjonell trening, styrke og utholdenhet
          av høy kvalitet. Vi tilbyr Kongsbergs beste frivektsavdeling innen
          styrketrening og har egen sone for funksjonell trening! Vi har bredt
          sortement av treningsutstyr for hele kroppen, og har som mål at ingen
          medlemmer skal være begrenset i sitt treningsarbeid på grunn av
          treningsutstyret.
        </Typography>

        <Typography variant="body1" paragraph>
          Vi ønsker at medlemmene våre skal være delaktige i avgjørelsen om
          hvilke utvidelser og forbedringer senteret skal prioritere. Hvis du
          som medlem har et ønske, ikke nøl med å{" "}
          <a href="#kontakt">ta kontakt</a> med oss!
        </Typography>

        <Typography id="parkering" variant="h3" component="h2">
          Parkering
        </Typography>

        <Typography id="bilparkering" variant="h4" component="h3">
          Bilparkering
        </Typography>

        <Typography variant="body1" paragraph>
          Våre medlemmer kan benytte seg av følgende parkeringsmuligheter for
          bil:
        </Typography>

        <Typography variant="body1" paragraph>
          <ol>
            <li>
              <b>Siden av senteret:</b> 8 plasser mellom kl 05:00 og 23:00.
              Krever parkeringsbevis, se nedenfor.
            </li>
            <li>
              <b>Taket av senteret:</b> 10 plasser mellom kl 16:00 og 23:00.
              Krever <i>inntil videre</i> ikke parkeringsbevis.
            </li>
            <li>
              <a
                href="https://storymaps.arcgis.com/stories/fe2b2f66996b4d6ea360eb8dbc2de2b6#ref-n-WHcz2f"
                target="_blank"
                rel="noreferrer"
              >
                <b>Gateparkering:</b>
              </a>{" "}
              Plasser på begge sider av innkjørselen til treningssenteret. 1
              time gratis frem til kl 18:00. Gratis etter kl 18:00. Gratis på
              søndager. Husk parkeringslapp/EasyPark.
            </li>
          </ol>
        </Typography>

        <Typography variant="body1" paragraph>
          Se hvor parkering og takparkering befinner seg på kartet nedenfor.
        </Typography>

        <Typography variant="body1" paragraph>
          <b>Parkeringsbevis</b> <br />
          For å benytte plassene på siden av senteret må forhåndssignert
          parkeringslapp hentes på resepsjonsdisken og legges i frontruten.
          Parkeringslappen skal inneholde fullt navn, og dato og tidspunkt for
          ankomst. <br />
          Parkering på taket krever ikke parkeringsbevis inntil videre.
        </Typography>

        <Typography id="bilparkering" variant="h4" component="h3">
          Sykkelparkering
        </Typography>

        <Typography variant="body1" paragraph>
          Sykkelparkering ligger på fremsiden av bygget, ved inngangspartiet. Se
          kartet nedenfor.
        </Typography>
        <br />
        <div>
          <ParkingMap />
        </div>
      </div>
    </>
  );
};

export default Venue;
