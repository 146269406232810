import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  ClickAwayListener,
  createStyles,
  Grid,
  makeStyles,
  Popper,
} from "@material-ui/core";
import { ArrowDropDown } from "@material-ui/icons";

const supportedLanguages: Record<string, { name: string; icon: string }> = {
  no: { name: "Norsk", icon: "norway-flag" },
  en: { name: "English", icon: "united-kingdom-flag" },
};

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    activeFlagContainer: {
      display: "flex",
      alignItems: "center",
      padding: spacing(1),
      borderRadius: 5,

      "&:hover": {
        cursor: "pointer",
        backgroundColor: "rgba(0, 0, 0, 0.1)",
      },
    },
    flag: {
      display: "block",
      height: 22,
      border: "1px solid black",
      borderRadius: 2,

      "&:hover": {
        cursor: "pointer",
      },
    },
    inactiveFlags: {
      marginTop: spacing(2),
      padding: spacing(1),
      backgroundColor: "rgba(255, 255, 255, 0.75)",
      border: "1px solid grey",
      borderRadius: 5,
    },
  })
);

const LanguagePicker = () => {
  const classes = useStyles();

  const { i18n } = useTranslation();
  const history = useHistory();

  const [isPopperOpen, setPopperOpen] = useState(false);

  // Generate a semi-random id in order to differentiate the anchorEl reference between
  // multiple instances of this component visible at the same time.
  const [id] = useState(`active-language-${Math.floor(Math.random() * 1000)}`);

  // Update the lang query param to the new locale.
  // App.tsx conducts the actual i18n.changeLanguage call based on that param.
  const setLanguage = (language: string) => {
    const params = new URLSearchParams();
    if (language !== "no") params.append("lang", language);
    history.replace({ search: params.toString() });
  };

  return (
    <>
      <div
        className={classes.activeFlagContainer}
        onClick={(e) => {
          setPopperOpen((v) => !v);
          e.stopPropagation();
        }}
      >
        <img
          id={id}
          className={classes.flag}
          src={`${process.env.PUBLIC_URL}/logos/${
            supportedLanguages[i18n.language!!].icon
          }.svg`}
          alt={supportedLanguages[i18n.language!!].name}
        />

        <ArrowDropDown
          style={{
            color: "#ddd",
            transform: `scaleY(${isPopperOpen ? -1 : 1})`,
          }}
        />
      </div>

      <ClickAwayListener
        onClickAway={() => (isPopperOpen ? setPopperOpen(false) : {})}
      >
        <Popper
          className={classes.inactiveFlags}
          open={isPopperOpen}
          anchorEl={document.querySelector(`#${id}`)}
          disablePortal
        >
          <Grid container direction="column" alignItems="center" spacing={1}>
            {Object.keys(supportedLanguages)
              .filter((language) => language !== i18n.language)
              .map((language) => (
                <Grid
                  item
                  key={language}
                  onClick={() => {
                    setLanguage(language);
                    setPopperOpen(false);
                  }}
                >
                  <img
                    style={{ border: "1px solid black" }}
                    className={classes.flag}
                    src={`${process.env.PUBLIC_URL}/logos/${supportedLanguages[language].icon}.svg`}
                    alt={supportedLanguages[language].name}
                  />
                </Grid>
              ))}
          </Grid>
        </Popper>
      </ClickAwayListener>
    </>
  );
};

export default LanguagePicker;
