import React, { FC, useEffect, useState } from "react";
import { matchPath, NavLink, useLocation } from "react-router-dom";
import {
  Collapse,
  createStyles,
  lighten,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SwipeableDrawer,
} from "@material-ui/core";
import {
  ExpandLess,
  ExpandMore,
  FitnessCenterOutlined,
  Help,
  Home,
  Info,
  Person,
} from "@material-ui/icons";

import LanguagePicker from "components/LanguagePicker";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(({ palette, spacing }) =>
  createStyles({
    root: {
      "& > .MuiDrawer-paper": {
        display: "flex",
        justifyContent: "space-between",
        backgroundColor: palette.primary.main,
        minWidth: 300,
      },
    },
    content: {
      color: "white",

      "& .MuiListItem-root": {
        height: 70,
        textTransform: "uppercase",
      },

      "& .MuiListItemIcon-root": {
        paddingRight: spacing(4),
      },

      "& > ul > .MuiListItem-root": {
        paddingLeft: spacing(4),
        paddingRight: spacing(4),
      },
      "& a > .MuiListItem-root": {
        paddingLeft: spacing(4),
        paddingRight: spacing(4),
      },

      "& a": {
        textDecoration: "none",
        color: "white",
      },

      "& svg": {
        color: "white",
      },
    },
    aboutMenu: {
      backgroundColor: lighten(palette.primary.main, 0.2),

      "& .MuiListItem-root": {
        paddingLeft: spacing(4.6),
      },

      "& $active .MuiListItem-root": {
        backgroundColor: lighten(palette.primary.main, 0.4),
      },
    },
    active: {
      "& .MuiListItem-root": {
        backgroundColor: lighten(palette.primary.main, 0.2),

        "& span": {
          fontWeight: 500,
        },
      },
    },
    languagePickerContainer: {
      alignSelf: "center",
      marginBottom: spacing(8),
    },
  })
);

interface IProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}

const Drawer: FC<IProps> = ({ open, onOpenChange }) => {
  const classes = useStyles();

  const location = useLocation();
  const { protocol, host } = window.location;

  const { t } = useTranslation();

  const isAtInfo = [
    "/generell-info",
    "/lokalet",
    "/andre-tjenester",
    "/rent-senter",
  ].some((path) => matchPath(location.pathname, { path }) !== null);
  const [isInfoOpen, setInfoOpen] = useState(isAtInfo);
  useEffect(() => {
    setInfoOpen(isAtInfo);
  }, [isAtInfo]);

  return (
    <SwipeableDrawer
      className={classes.root}
      anchor="right"
      open={open}
      onOpen={() => onOpenChange(true)}
      onClose={() => onOpenChange(false)}
    >
      <div className={classes.content} role="presentation">
        <List>
          <NavLink
            to=""
            exact
            activeClassName={classes.active}
            onClick={() => onOpenChange(false)}
          >
            <ListItem button key="home">
              <ListItemIcon>
                <Home fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={t("menu.home")} />
            </ListItem>
          </NavLink>

          <NavLink
            to="/ofte-stilte-sporsmal"
            exact
            activeClassName={classes.active}
            onClick={() => onOpenChange(false)}
          >
            <ListItem button key="faq">
              <ListItemIcon>
                <Help fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={t("menu.faq")} />
            </ListItem>
          </NavLink>

          <ListItem button key="info" onClick={() => setInfoOpen(!isInfoOpen)}>
            <ListItemIcon>
              <Info fontSize="large" />
            </ListItemIcon>
            <ListItemText primary="Info" />
            {isInfoOpen ? <ExpandLess /> : <ExpandMore />}
          </ListItem>

          <Collapse
            className={classes.aboutMenu}
            in={isInfoOpen}
            timeout="auto"
            unmountOnExit
          >
            <NavLink
              to="/generell-info"
              exact
              activeClassName={classes.active}
              onClick={() => onOpenChange(false)}
            >
              <ListItem button key="general-info">
                <ListItemText primary={t("menu.info.general")} />
              </ListItem>
            </NavLink>

            <NavLink
              to="/lokalet"
              exact
              activeClassName={classes.active}
              onClick={() => onOpenChange(false)}
            >
              <ListItem button key="venue">
                <ListItemText primary={t("menu.info.venue")} />
              </ListItem>
            </NavLink>

            <NavLink
              to="/andre-tjenester"
              exact
              activeClassName={classes.active}
              onClick={() => onOpenChange(false)}
            >
              <ListItem button key="services">
                <ListItemText primary={t("menu.info.other-services")} />
              </ListItem>
            </NavLink>

            <NavLink
              to="/rent-senter"
              exact
              activeClassName={classes.active}
              onClick={() => onOpenChange(false)}
            >
              <ListItem button key="rent-senter">
                <ListItemText primary="Rent senter" />
              </ListItem>
            </NavLink>
          </Collapse>

          <a
            href={`${process.env.REACT_APP_SIGNUP_URL}?returnUrl=${
              protocol + "//" + host
            }`}
          >
            <ListItem button key="signup">
              <ListItemIcon>
                <FitnessCenterOutlined fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={t("sign_up")} />
            </ListItem>
          </a>

          <a href={process.env.REACT_APP_MYPAGE_URL}>
            <ListItem button key="login">
              <ListItemIcon>
                <Person fontSize="large" />
              </ListItemIcon>
              <ListItemText primary={t("my_page")} />
            </ListItem>
          </a>
        </List>
      </div>

      <div className={classes.languagePickerContainer}>
        <LanguagePicker />
      </div>
    </SwipeableDrawer>
  );
};

export default Drawer;
