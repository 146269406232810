import React from "react";
import {
  createStyles,
  makeStyles,
  ListItemIcon,
  Grid,
  Typography,
} from "@material-ui/core";
import { CheckCircleOutlined } from "@material-ui/icons";

const useStyles = makeStyles(({ spacing }) =>
  createStyles({
    successPage: {
      maxWidth: 500,
      flex: 1,
      alignSelf: "center",
      marginTop: spacing(1),
      marginBottom: spacing(1),
      textAlign: "center",
    },

    checkIcon: {
      color: "#4caf50",
      fontSize: "4rem",
    },
  })
);

const SuccessMessage = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      id="successPage"
      className={classes.successPage}
      spacing={2}
    >
      <Grid item xs={12}>
        <ListItemIcon>
          <CheckCircleOutlined className={classes.checkIcon} />
        </ListItemIcon>
        <Typography variant="h5">Bestilling sendt.</Typography>
        <Typography variant="h5">Vennligst vent på svar per e-post.</Typography>
      </Grid>
    </Grid>
  );
};

export default SuccessMessage;
