import React, { FC } from "react";
import {
  Container,
  createStyles,
  lighten,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(({ palette, spacing, breakpoints }) =>
  createStyles({
    root: {
      backgroundColor: lighten(palette.primary.main, 0.8),
      padding: spacing(8),

      "&:nth-child(2n)": {
        backgroundColor: "white",
      },

      [breakpoints.only("xs")]: {
        padding: spacing(2),
        paddingTop: spacing(4),
      },
    },
    title: {
      marginBottom: spacing(2),
      fontWeight: 700,
      textTransform: "uppercase",
    },
  })
);

interface IProps {
  title?: string;
}

const Section: FC<IProps> = ({ title, children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Container disableGutters>
        {title && (
          <Typography className={classes.title} component="h2" variant="h5">
            {title}
          </Typography>
        )}

        {children}
      </Container>
    </div>
  );
};

export default Section;
