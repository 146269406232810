import React from "react";
import { Helmet } from "react-helmet";
import { createStyles, makeStyles, Typography, Grid } from "@material-ui/core";
import Link from "components/Link";

const useStyles = makeStyles(({ breakpoints }) =>
  createStyles({
    topSection: {
      paddingBottom: "60px",
      alignItems: "flex-start",

      [breakpoints.between("xs", "sm")]: {
        paddingBottom: "20px",
      },

      "& > div > img": {
        width: "100%",
        border: "1px solid #aaaaaa",
      },
    },
    contentGrid: {
      width: "100%",
    },
    textGrid: {
      paddingRight: "70px",
      width: "50%",

      [breakpoints.between("xs", "sm")]: {
        flexDirection: "column",
        paddingRight: "0",
      },
    },
    imgGrid: {
      width: "50%",
      "& img": {
        width: "80%",
      },
    },
    img: {
      border: "1px solid grey",
    },
  })
);

const OtherServices = () => {
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Informasjon om våre tjenester utover trening slik som butikk/shop, solarium og gratis Wi-Fi."
        />
      </Helmet>

      <div>
        <Typography variant="h1">ANDRE TJENESTER</Typography>

        <Grid container className={classes.topSection}>
          <Grid item container xs={12}>
            <Typography id="butikk" variant="h3" component="h2">
              Butikk
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={12} className={classes.contentGrid}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              className={classes.textGrid}
            >
              <Typography variant="body1" paragraph>
                Vi tilbyr en stor butikk med godt utvalg av kostholdsprodukter,
                i tillegg til diverse treningsrelaterte produkter.
                <br />
                <br />
                Vi fører blant annet kjente merkevarer slik som Star Nutrition,
                Nutramino, Barebells, Propud, Vitamin-Well og Monster.
                <br />
                <br />
                Butikken er tilgjengelig for medlemmer i treningssenterets{" "}
                <Link to="/generell-info#apningstid">åpningstid</Link>.
                Ikke-medlemmer som ønsker å handle i vår butikk må komme innom i{" "}
                <Link to="/generell-info#resepsjonstider">resepsjonstiden</Link>
                .
                <br />
                <br />
                Betaling gjøres enkelt ved hjelp av Vipps.
              </Typography>
            </Grid>

            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              className={classes.imgGrid}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/benefits/shop.jpg`}
                alt="Kostholdsbutikk med stort utvalg."
                className={classes.img}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.topSection}>
          <Grid item container xs={12}>
            <Typography id="solarium" variant="h3" component="h2">
              Solarium
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={12} className={classes.contentGrid}>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              className={classes.textGrid}
            >
              <Typography variant="body1" paragraph>
                Top Notch Fitness har en MegaSun solseng i et hyggelig separat
                rom.
                <br />
                <br />
                Medlemmer over 18 år kan benytte seg av solariet til kun kr 2,-
                per minutt.
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              sm={12}
              md={6}
              className={classes.imgGrid}
            >
              <img
                src={`${process.env.PUBLIC_URL}/images/benefits/sunbed.jpg`}
                alt="Hyggelige solariumsrom."
                className={classes.img}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className={classes.topSection}>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={6}
            className={classes.textGrid}
          >
            <Typography id="solarium" variant="h3" component="h2">
              WiFi/Internett
            </Typography>

            <Typography variant="body1" paragraph>
              I treningslokalet kan du koble deg på gratis trådløst internett
              med høy hastighet.
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default OtherServices;
