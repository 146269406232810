import React, { FC, useState } from "react";
import {
  createMuiTheme,
  createStyles,
  CssBaseline,
  lighten,
  makeStyles,
  Theme,
  ThemeProvider,
} from "@material-ui/core";
import { MuiPickersOverrides } from "@material-ui/pickers/typings/overrides";
import { useTranslation } from "react-i18next";
import { MessengerChat } from "react-messenger-chat-plugin";

import useScrollPos from "hooks/useScrollPos";
import { scaleBetween } from "helpers";
import Footer from "./Footer";
import Header from "./Header";
import Drawer from "./Drawer";

type overridesNameToClassKey = {
  [P in keyof MuiPickersOverrides]: keyof MuiPickersOverrides[P];
};

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey extends overridesNameToClassKey {}
}

const TNF_COLOR = "rgb(8, 143, 232)";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: TNF_COLOR,
    },
  },

  typography: {
    fontFamily: "Gilroy, Arial",
    fontSize: 16,

    h1: {
      fontSize: "2em",
      fontWeight: 700,
      marginTop: 24,
      marginBottom: 16,
    },
    h2: {
      fontSize: "1.5em",
      fontWeight: 700,
      marginTop: 24,
      marginBottom: 16,
    },
    h3: {
      fontSize: "1.2em",
      fontWeight: 700,
      marginTop: 24,
      marginBottom: 8,
      letterSpacing: "0.04rem",
    },
    h4: {
      fontSize: "1.1em",
      fontWeight: 600,
      marginTop: 24,
      marginBottom: 4,
    },
    body1: {
      fontSize: "1.05em",
    },

    fontWeightBold: 600,
  },

  overrides: {
    MuiCssBaseline: {
      "@global": {
        "*::-webkit-scrollbar": {
          width: 12,
          backgroundColor: "#f5f5f5",
        },
        "*::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 10px rgba(0,0,0,0.00)",
          backgroundColor: lighten(TNF_COLOR, 0.8),
        },
        "*::-webkit-scrollbar-thumb": {
          backgroundColor: lighten(TNF_COLOR, 0.3),

          "&:hover": {
            backgroundColor: lighten(TNF_COLOR, 0.5),
          },
        },
      },
    },
    MuiPickersTimePickerToolbar: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: 60,
        },
      },
      separator: {
        margin: "0 4px 20px 4px",
      },
    },
  },
});

interface IStyleProps {
  scroll: number;
}

const useStyles = makeStyles<Theme, IStyleProps>(({ transitions }) =>
  createStyles({
    flex: {
      display: "flex",
      flexDirection: "column",
      height: "100%",

      "& > div:first-of-type": {
        flex: 1,
      },
      "& > div:nth-of-type(2)": {
        flex: 0,
      },
    },
    content: {
      backgroundColor: "white",
      transition: transitions.create("all", {
        duration: 100,
        easing: "linear",
      }),

      "& a": {
        color: TNF_COLOR,
      },

      "@media (min-height: 850px)": {
        marginTop: (props) => `${scaleBetween(100, 70, props.scroll / 150)}px`,
      },
      "@media (max-height: 850px)": {
        marginTop: (props) => `${scaleBetween(80, 60, props.scroll / 150)}px`,
      },
    },
  })
);

const Layout: FC = ({ children }) => {
  const classes = useStyles({ scroll: useScrollPos() });

  const { t } = useTranslation();

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />

      <Header openDrawer={setDrawerOpen} />

      <Drawer open={isDrawerOpen} onOpenChange={setDrawerOpen} />

      <div className={classes.flex}>
        <div className={classes.content}>{children}</div>

        <Footer />
      </div>

      <div style={{ display: !isDrawerOpen ? "initial" : "none" }}>
        <MessengerChat
          pageId="256161614818478"
          language="nb_NO"
          themeColor="#088fe8"
          loggedInGreeting={t("app.messenger_chat_greeting")}
          loggedOutGreeting={t("app.messenger_chat_greeting")}
          greetingDialogDisplay="icon"
          version="v15.0"
        />
      </div>
    </ThemeProvider>
  );
};

export default Layout;
