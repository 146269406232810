import React, { MutableRefObject, useEffect, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { createStyles, lighten, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(({ palette }) =>
  createStyles({
    root: {
      height: "calc(100vw - 64px)",
      maxHeight: 400,
      border: "1px solid " + lighten(palette.primary.main, 0.7),
      borderRadius: 5,
    },
  })
);

const loader = new Loader({
  apiKey: "AIzaSyClkKzex4sECe4QbdAL5oHDd4oV9Z8BUJY",
});

const mapOptions = {
  center: { lat: 59.66399, lng: 9.6450 },
  zoom: 16,
  mapTypeControl: false,
};
const tnfPosition = { lat: 59.663148, lng: 9.644238 };

const Map = () => {
  const classes = useStyles();

  const mapRef = useRef() as MutableRefObject<HTMLDivElement>;

  useEffect(() => {
    loader
      .load()
      .then(() => {
        // @ts-ignore
        const map = new google.maps.Map(mapRef.current, mapOptions);
        // @ts-ignore
        new google.maps.Marker({
          position: tnfPosition,
          icon: `${process.env.PUBLIC_URL}/map/maps_locator.png`,
          map: map,
        });
      })
      .catch(() => {});
  }, []);

  return <div className={classes.root} ref={mapRef} />;
};

export default Map;
