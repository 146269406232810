import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  AppBar,
  Button,
  Container,
  createStyles,
  darken,
  Grid,
  Hidden,
  makeStyles,
  Theme,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { FitnessCenterOutlined, Menu, Person } from "@material-ui/icons";

import useScrollPos from "hooks/useScrollPos";
import LanguagePicker from "components/LanguagePicker";
import { scaleBetween } from "helpers";

interface IStyleProps {
  scroll: number;
}

const useStyles = makeStyles<Theme, IStyleProps>(
  ({ palette, spacing, breakpoints, transitions }) => {
    const transition = transitions.create("all", {
      duration: 100,
      easing: "linear",
    });

    return createStyles({
      root: {
        padding: `0 ${spacing(8)}px`,
        backgroundColor: palette.primary.main,

        transition,

        [breakpoints.only("xs")]: {
          padding: `0 ${spacing(1)}px`,
        },

        [breakpoints.only("sm")]: {
          padding: `0 ${spacing(2)}px`,
        },

        "@media (min-height: 850px)": {
          height: (props) => `${scaleBetween(100, 70, props.scroll / 150)}px`,
        },
        "@media (max-height: 850px)": {
          height: (props) => `${scaleBetween(80, 60, props.scroll / 150)}px`,
        },
      },

      container: {
        height: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "& a": {
          textDecoration: "none",
        },
      },

      logo: {
        display: "block",
        height: "60%",
        padding: "0 11px",

        transition,

        "@media (min-width: 600px)": {
          height: "50%",
        },

        "&:hover": {
          cursor: "pointer",
        },
      },

      signupButton: {
        flexGrow: 0,
        color: palette.primary.main,
        backgroundColor: "white",
        marginRight: 0,
        whiteSpace: "nowrap",

        "&:hover": {
          backgroundColor: darken("#ffffff", 0.1),
        },
        "&:focus": {
          backgroundColor: "white",
        },

        [breakpoints.down("xs")]: {
          marginRight: 0,
        },
      },

      mypageButton: {
        color: "#ffffff",
        whiteSpace: "nowrap",

        "&:hover, &:focus": {
          backgroundColor: darken(palette.primary.main, 0.05),
        },

        [breakpoints.down("sm")]: {
          marginRight: 0,
        },
      },

      menuButton: {
        color: "white",
        margin: 0,

        "& svg": {
          fontSize: "46px !important",
        },

        [breakpoints.only("xs")]: {
          "& .MuiButton-endIcon": {
            marginLeft: 0,
          },
        },
      },
      menuButtonEndIcon: {
        margin: 0,
      },
    });
  }
);

interface IProps {
  openDrawer: (open: boolean) => void;
}

const Header: FC<IProps> = ({ openDrawer }) => {
  const scroll = useScrollPos();
  const classes = useStyles({ scroll });

  const { protocol, host } = window.location;
  const { t } = useTranslation();

  const history = useHistory();
  const theme = useTheme();

  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const minW400 = useMediaQuery("(min-width: 400px)");
  const minW615 = useMediaQuery("(min-width: 615px)");

  const signupButton = (
    <Button
      className={classes.signupButton}
      size="large"
      endIcon={minW400 && <FitnessCenterOutlined />}
      href={`${process.env.REACT_APP_SIGNUP_URL}?returnUrl=${
        protocol + "//" + host
      }`}
      onClick={() => openDrawer(false)}
    >
      {t("sign_up")}
    </Button>
  );

  const mypageButton = (
    <Button
      className={classes.mypageButton}
      size={isSmUp ? "large" : "small"}
      endIcon={minW615 && <Person />}
      href={process.env.REACT_APP_MYPAGE_URL}
      onClick={() => openDrawer(false)}
    >
      {t("my_page")}
    </Button>
  );

  const menuButton = (
    <Button
      className={classes.menuButton}
      classes={{ endIcon: classes.menuButtonEndIcon }}
      size="large"
      endIcon={<Menu />}
      onClick={() => openDrawer(true)}
      aria-label="Meny"
    />
  );

  return (
    <AppBar className={classes.root} elevation={scroll > 0 ? 6 : 0}>
      <Container className={classes.container} disableGutters>
        <img
          className={classes.logo}
          src={`${process.env.PUBLIC_URL}/logos/logo${
            isXs ? "-small" : ""
          }.svg`}
          alt="Top Notch Fitness Logo"
          onClick={() => history.push("")}
        />

        <Hidden smUp>
          <div>{signupButton}</div>
        </Hidden>

        <Hidden xsUp>
          <div>{mypageButton}</div>
        </Hidden>

        <Hidden smUp>
          <div>{menuButton}</div>
        </Hidden>

        <Hidden xsDown>
          <Grid container justify="flex-end" alignItems="center" spacing={2}>
            <Grid item>{signupButton}</Grid>
            <Grid item>{mypageButton}</Grid>
            <Grid item>
              <LanguagePicker />
            </Grid>
            <Grid item>{menuButton}</Grid>
          </Grid>
        </Hidden>
      </Container>
    </AppBar>
  );
};

export default Header;
